@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  min-height: 100vh;
}

*{
  border: 0;
  margin: 0;
  box-sizing: border-box;
}

.bg-primary{
  @apply bg-slate-200
}

.dropdown-hidden{
    /* visibility: hidden; */
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.15s ease-out;
}

.dropdown-visible{
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

thead{
  border-radius: 3rem;
  text-align: left;
}

thead tr th{
  @apply p-3;
}

tbody{
  @apply divide-y-2;
  height: 24rem;
  overflow-y: scroll;
}

tbody tr td{
  @apply p-3
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.admin-dashbord-height{
  height: 30rem;
  min-height:30rem;
}

.button-blue{
  @apply p-3 w-fit font-bold text-white rounded-xl bg-blue-500 cursor-pointer hover:bg-blue-700
}

.button-red{
  @apply p-3 w-fit font-bold text-white rounded-xl bg-red-500 cursor-pointer hover:bg-red-700
}

.button-stone{
  @apply p-3 w-fit font-bold text-white rounded-xl bg-stone-800 cursor-pointer hover:bg-stone-600
}

.submit-button-black{
  padding: 0.55rem;
  font-size: small;
  background-color: rgb(41,37,36);
  color: white;
  border-radius: 0.75rem;
}


.custom-datepicker-dropdown {
  top: 100% !important;
  left: 0 !important;
}

.custom-datepicker-dropdown .ant-picker-panel-container {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
